import React, { useState } from 'react';
import clsx from 'clsx';

const listItems = [
  'In-depth medical visits with your medical provider.',
  'Health coach visits to address your recommended lifestyle changes and goals, adjusting as you go.',
  'A personalized health plan for lifestyle, nutrition, sleep, movement, and mental wellbeing.',
  'Access to advanced diagnostic testing for hormones, genetics, toxin exposure, and more.',
  'Convenient online scheduling',
  'Unlimited messaging with your medical team using your My Parsley Health portal between visits.',
  'Prescriptions for medications and supplements all in one place',
  <div key="discount">
    20% discounted membership rate at{' '}
    <a
      href="https://store.parsleyhealth.com/"
      target="_blank"
      rel="noreferrer"
      className="text-ph-sage underline"
    >
      our online supplement store.
    </a>
  </div>
];

const CheckmarkIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M6 12.7485L10.5 16.5L18 7.5"
        stroke="#4F7F71"
        strokeWidth="1.2"
        strokeMiterlimit="10"
      />
    </svg>
  );
};

enum PaymentType {
  IN_NETWORK = 'In-Network',
  OUT_OF_NETWORK = 'Out-of-Network'
}

export const PricingModule = () => {
  const [paymentType, setPaymentType] = useState<PaymentType>(
    PaymentType.IN_NETWORK
  );

  const togglePaymentType = () => {
    setPaymentType(
      paymentType === PaymentType.IN_NETWORK
        ? PaymentType.OUT_OF_NETWORK
        : PaymentType.IN_NETWORK
    );
  };
  const isProduction = process.env.GATSBY_ACTIVE_ENV === 'production';

  return (
    <div className="flex flex-col gap-8 bg-ph-gradient-mint px-6 py-16 ph-font-euclid-regular">
      {/* Header */}
      <div className="flex flex-col text-center text-ph-forest gap-6 max-w-screen-md mx-auto">
        <div className="text-[28px] font-medium">
          Root Cause healthcare is now more accessible than ever.
        </div>
        <div className="text-lg md:text-xl">
          Transparent pricing for a long-term partnership that combines the best
          of healthcare with innovative root cause-focused care.
        </div>
      </div>
      {/* Card */}
      <div className="flex flex-col md:flex-row bg-white rounded-xl px-4 py-8 md:p-12 lg:p-16 border-ph-ivory shadow-[0_0_2px_0px_rgba(51,51,51,0.15)] w-full max-w-screen-sm md:max-w-6xl mx-auto">
        <div className="flex flex-col gap-12 md:mr-12 lg:mr-auto">
          {/* Tabs */}
          <div className="flex">
            {[PaymentType.IN_NETWORK, PaymentType.OUT_OF_NETWORK].map(type => {
              return (
                <button
                  key={type}
                  onClick={togglePaymentType}
                  disabled={paymentType === type}
                  className={clsx(
                    'px-4',
                    'py-2',
                    'w-1/2',
                    'md:w-[160px]',
                    'border',
                    'border-ph-sage',
                    'opacity-40',
                    'disabled:opacity-100',
                    'disabled:bg-ph-sage',
                    'disabled:text-white',
                    'text-sm',
                    'xl:text-base',
                    {
                      'rounded-l-full -mr-1': type === PaymentType.IN_NETWORK,
                      'rounded-r-full -ml-1':
                        type === PaymentType.OUT_OF_NETWORK
                    }
                  )}
                >
                  {type}
                </button>
              );
            })}
          </div>
          {/* Price */}
          <div className="flex flex-col gap-2 text-center">
            <div className="text-[64px] lg:text-8xl font-medium text-ph-sage leading-none">
              {paymentType === PaymentType.IN_NETWORK ? '$79' : '$199'}
            </div>
            <div className="text-ph-forest lg:text-lg">
              monthly or{' '}
              {paymentType === PaymentType.IN_NETWORK ? '$869' : '$2,189'}{' '}
              annually
            </div>
            {paymentType === PaymentType.IN_NETWORK && (
              <div
                className="text-center font-euclid text-[14px] font-normal leading-[160%] text-ph-grey-text"
                style={{
                  maxWidth: '320px'
                }}
              >
                Medical visits are not included in the membership fee, and are
                billed through insurance, just like visits with a non-Parsley
                medical provider.
              </div>
            )}
          </div>
          <a
            href={
              isProduction
                ? 'https://join.parsleyhealth.com/buy/complete-care'
                : 'https://join.staging.parsleyhealth.com/buy/complete-care'
            }
            className="bg-ph-forest text-white text-center px-8 py-4 rounded-full text-lg font-euclid hover:bg-opacity-90 transition duration-300"
          >
            Join Parsley Today
          </a>
        </div>
        <ul className="flex flex-col gap-4 w-full md:max-w-[480px] text-sm lg:text-base">
          {listItems.map((item, i) => {
            return (
              <li key={'priceList' + i} className="flex gap-2 text-ph-forest">
                <CheckmarkIcon className="min-w-6" /> {item}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
